.box-outer-container {
    padding: 10px 1px 10px 0px;
    bottom: 1%;
    margin: 0rem 1rem 1rem 1rem;
}

.configure-msg-box {
    font-size: 0.875rem;
    padding: 1px 1px 10px 0px;
}

.config-box-main {
    bottom: 0;
}
