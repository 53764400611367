.msg-dropdown-content {
    flex-direction: column;
    position: absolute;
    background-color: var(--background-container);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border: 1px solid #000000;
    border-radius: 5px;
    margin-top: 1.2rem;
}

.own-msg {
    margin-left: -8.7rem;
}

.msg-dropdown-content-top-align {
    margin-top: -6.9rem !important;
}

.msg-dropdown-content div {
    color: var(--text-primary-color);
    padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.msg-dropdown-content div:hover {
    background-color: var(--normal-btn);
}

.msg-reaction {
    padding: 0.2rem 0.2rem 0.2rem 0.2rem !important;
}

.msg-reaction:hover {
    background-color: var(--background-container) !important;
}

.msg-reaction-container {
    font-size: 1.2rem;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem !important;
}

.msg-reaction-container:hover {
    background-color: var(--normal-btn);
}

.msg-react-separator {
    height: 1px;
    width: 95%;
    margin: 0rem 0rem 0rem 0.3rem;
}

.align-top {
    bottom: 1rem !important;
}

.align-action-item-left {
    margin-left: -7rem;
    width: fit-content;
    min-width: 130px;
}

#msg-dropdown:hover{
    .msg-action-btn{
        background: var(--normal-btn-hover);
        color: var(--text-primary-color);
    }
}
/* =================== Mobile Responsive CSS =================== */

@media only screen and (max-width: 800px) {
    .msg-reaction-container {
        font-size: 1rem;
    }
    .msg-react-separator {
        margin: 0rem 0rem 0rem 0rem;
    }
}
