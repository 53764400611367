.overlayStyle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

}

.popupStyle {
    background-color: #fff8f0;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10000;
    margin-left: 20%;
    margin-right: 20%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.warningButton {
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    background-color: #ec8129;
    color: #fff8f0;
    font-weight: bold;
    border: none;
    border-radius: 5px;
}

.infoText {
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    color: #5d5d5d;
    font-weight: bold;
}

.buttonGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
    width: fit-content;
}

.actionButton {
    margin-top: 20px;
    background-color: #ec8129;
    color: #fff8f0;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    border: none;
    border-radius: 40px;
}

.closeButton, .answerButton {
    margin-top: 20px;
    background-color: #5d5d5d;
    color: #fff8f0;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    border: none;
    border-radius: 40px;
}

.congratulationsText, .errorText, .seedText, .questionText {
    color: #5d5d5d;
    font-weight: bold;
    font-size: 16px;
}

.copyAllContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    cursor: pointer;
}

.copyIcon {
    font-size: 24px;
    color: #ec8129;
    margin-right: 8px;
}

.copyIcon:hover {
    color: #ff9c40;
}

.copyText {
    font-size: 16px;
    color: #ec8129;
    font-weight: bold;
}

.copyAllContainer:hover .copyText {
    color: #ff9c40;
}

.inputWrapper {
    display: inline-block;
}

.hiddenSpan {
    visibility: hidden;
    white-space: pre;
    font-size: 16px;
    position: absolute;
    left: -9999px;
    top: 0;
}

.seedWordsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;
}

.seedWordInput {
    font-size: 16px;
    padding: 5px 10px;
    border: 2px solid #ec8129;
    border-radius: 5px;
    margin: 5px;
    box-sizing: content-box;
    text-align: center;
}

.seedWord {
    border: 2px solid #ec8129;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
    background-color: #fff;
    color: #5d5d5d;
    font-weight: bold;
}

.overlayStyle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popupStyle {
    background-color: #fff8f0;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10000;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
}

.closeButton {
    margin-top: 20px;
    background-color: #5d5d5d;
    color: #fff8f0;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    border: none;
    border-radius: 40px;
}

.closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #5d5d5d;
}

.closeIcon:hover {
    color: #ec8129;
}

.top {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideIn {
    from { transform: translateY(-10px); }
    to { transform: translateY(0); }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes slideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10px); }
}

.fadeIn {
    animation: fadeIn 0.3s;
}

.slideIn {
    animation: slideIn 0.3s;
}

.fadeOut {
    animation: fadeOut 0.3s;
}

.slideOut {
    animation: slideOut 0.3s;
}
