.delete-name-modal-content {
    margin: auto;
    padding: 2rem;
    border: none;
    top: 30%;
    position: relative;
    width: 45%;
    height: auto;
}

.delete-name-btn {
    padding: 0.5rem 2rem 0.5rem 2rem;
}
