.contacts-scroller {
    height: calc(100% - 50px) !important;
    margin-top: 2px;
}

.scroller-hidden {
    overflow: scroll;
}

.scroller-active {
    overflow: auto;
}

.contacts-scroller::-webkit-scrollbar {
    display: none;
}

.contact-details-container {
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
}

.contact-details-container:hover .action-container {
    display: block !important;
}

.pic {
    height: 3rem;
    width: 3rem;
}

.content {
    padding-left: 0.5rem;
}

.content p {
    margin-bottom: 0;
}

.action-container {
    display: none;
    border-radius: 3px;
    border: 1px solid var(--text-primary-color);
    position: relative;
    padding: 0px 2px 0px 2px;
}

.action-container:hover {
    background-color: var(--normal-btn-hover);
}

.action-container:hover > .dropdown-content {
    display: flex;
}

.action-dot {
    height: 0.8rem;
    width: 0.8rem;
}

.contacts-msg {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.hidden-data {
    height: 4rem;
    visibility: hidden;
}

.active-contact-border {
    border-top: 2px solid var(--normal-btn-hover);
    border-left: 2px solid var(--normal-btn-hover);
    border-bottom: 2px solid var(--normal-btn-hover);
}

.contact-details-container:hover {
    border-radius: 0px;
    background-color: var(--normal-btn);
}

.contact-details-container-active {
    border-top: 2px solid var(--selected-contact-border-color) !important;
    border-left: 2px solid var(--selected-contact-border-color) !important;
    border-bottom: 2px solid var(--selected-contact-border-color) !important;
    background: var(--background-active-contact) !important;
    top: 0;
    bottom: 4rem !important;
    position: sticky;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    padding-left: 0.5rem !important;
    margin-left: 1rem !important;
}

.msg-count {
    border-radius: 3px;
    background: var(--msg-counter-background-color);
    padding: 1px 7px 1px 7px;
    color: var(--msg-counter-text-color);
}

.display-name {
    font-weight: bolder;
}

.last-hidden-contact{
    flex-grow: 1;
}

.paginated-contacts{
    height: 100% !important;
}

/* =================== Mobile Responsive CSS =================== */

@media only screen and (max-width: 800px) {
    .contacts-scroller {
        padding-left: 0rem !important;
    }

    .contact-details-container {
        padding: 0.5rem 0.5rem 0.5rem 2rem !important;
    }

    .contact-details-container:nth-child(even) {
        background: var(--alternate-contact-background-color) !important;
    }

    .contact-details-container:hover {
        border-radius: 0px;
        padding-left: 2rem !important;
        margin-left: 0rem !important;
        background-color: var(--normal-btn) !important;
    }

    .pic {
        height: 2rem !important;
        width: 2rem !important;
    }

    .display-name {
        font-size: 10px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .contacts-msg {
        font-size: 10px !important;
    }

    .configure-msg-box {
        font-size: 12px !important;
    }

    .config-btn-container {
        font-size: 12px !important;
    }
}