.error-modal-content {
    margin: auto;
    padding: 20px;
    border: none;
    width: 40%;
}

.error-modal-spinner {
    display: none !important;
}

.error-close:hover,
.error-close:focus {
    text-decoration: none;
    cursor: pointer;
}

#error-message {
    margin: 0px;
}

#ok-btn {
    padding: 0.3rem 2rem 0.3rem 2rem;
}

@media only screen and (max-width: 800px) {
    .error-modal-content {
        width: 50%;
    }
}

@media only screen and (max-width: 500px) {
    .error-modal-content {
        width: 70%;
    }
}