.delete-msg-modal-content {
    margin: auto;
    padding: 2rem;
    border: none;
    top: 30%;
    position: relative;
    width: 32%;
    height: auto;
}

.delete-msg-btn {
    padding: 0.5rem 2rem 0.5rem 2rem;
}

@media only screen and (max-width: 1000px) {
    .delete-msg-modal-content {
        width: 50%;
    }
}

@media only screen and (max-width: 800px) {
    .delete-msg-modal-content {
        width: 60%;
    }
}

@media only screen and (max-width: 600px) {
    .delete-msg-modal-content {
        width: 85%;
    }
}