/* Absolute Center Spinner */
.loading {
    position: absolute;
    z-index: 999;
    height: 100%;
    width: 100%;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none !important;
}

/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
    background: -webkit-radial-gradient(
        rgba(20, 20, 20, 0.8),
        rgba(0, 0, 0, 0.8)
    );
}


.loader-content {
    color: var(--text-primary-color);
    position: absolute;
    margin-top: 7rem;
}

.loader-img {
    height: 3rem;
}

/* =================== Mobile Responsive CSS =================== */

@media only screen and (max-width: 800px) {
    .loader-img {
        height: 2rem !important;
    }
    .loader-content{
        font-size: 14px !important;
        margin-top: 4rem !important;
    }
}